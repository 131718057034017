import {Injectable} from '@angular/core';

interface OrderConfig {
    param: string;
    group: any[];
    altParam?: string;
}

@Injectable()
export class OrderByService {
    sortBy: string = '';
    currentSortDirection: string = 'asc';
    currentSortParam: string = '';
    currentPage: string = '';

    resetService() {
        this.sortBy = '';
        this.currentSortDirection = 'asc';
        this.currentSortParam = '';
    }

    orderByParam(param, group, altParam?, currentPage?,documents?) {
        console.log(documents);
        const presort = group;
        let final = [...presort];
        if(
            param === 'settlementDate' || 
            param === 'postingDate' || 
            param === 'tradeDate' || param === 'documentDate' || 
            param === 'face' ||
            param === 'cost' ||
            param === 'marketValue' ||
            param === 'marketPrice' ||
            param === 'purchPrice' ||
            param === 'fxRate' ||
            param === 'quantity' ||
            param === 'settlementAmount' ||
            param === 'price' ||
            param === 'debit' ||
            param === 'credit' ||
            param === 'balance' ||
            param === 'daysAccrued' ||
            param === 'accruedInterest'
        ){
            var dateOrNum = true;
        } else {
            var dateOrNum = false;
        };
        if(
            (param === this.currentSortParam && this.currentSortDirection === 'desc' && this.currentPage === currentPage) || 
            (dateOrNum && this.currentPage !== currentPage) || 
            (param !== this.currentSortParam && dateOrNum)
        ) {

            console.log('asc');
            final = this.orderByParamAscending(param, presort, altParam);
            this.currentSortDirection = 'asc';
            this.sortBy = `${param}up`;
           
        }      
        else {
            console.log('desc');
            final = this.orderByParamDescending(param, presort, altParam);
            this.currentSortDirection = 'desc';
            this.sortBy = `${param}down`;
        }
        if(documents=="documetnsdesc")
        {
            console.log('desc');
            final = this.orderByParamDescending(param, presort, altParam);
            this.currentSortDirection = 'desc';
            this.sortBy = `${param}down`;
        }
        



        this.currentSortParam = param;
        this.currentPage = currentPage;
        return final;
    }
    orderByParamdes(param, group, altParam?, currentPage?) {
        const presort = group;
        let final = [...presort];
        if(
            param === 'settlementDate' || 
            param === 'postingDate' || 
            param === 'tradeDate' || param === 'documentDate' || 
            param === 'face' ||
            param === 'cost' ||
            param === 'marketValue' ||
            param === 'marketPrice' ||
            param === 'purchPrice' ||
            param === 'fxRate' ||
            param === 'quantity' ||
            param === 'settlementAmount' ||
            param === 'price' ||
            param === 'debit' ||
            param === 'credit' ||
            param === 'balance' ||
            param === 'daysAccrued' ||
            param === 'accruedInterest'
        ){
            var dateOrNum = true;
        } else {
            var dateOrNum = false;
        };
        if(
            (param === this.currentSortParam && this.currentSortDirection === 'desc' && this.currentPage === currentPage) || 
            (dateOrNum && this.currentPage !== currentPage) || 
            (param !== this.currentSortParam && dateOrNum)
        ) {
             console.log('asc');
            final = this.orderByParamAscending(param, presort, altParam);
            this.currentSortDirection = 'asc';
            this.sortBy = `${param}up`;
            
        }  else {
           console.log('desc');
            final = this.orderByParamDescending(param, presort, altParam);
            this.currentSortDirection = 'desc';
            this.sortBy = `${param}down`;
        }
        this.currentSortParam = param;
        this.currentPage = currentPage;
        return final;
    }

    //order ascending- start with lowest
    orderByParamAscending(param, group, altParam?)  {
        return group.sort((a, b): any => {
            //account for possible null values - set to empty str if so
            if((!a[param] && typeof a[param] !== 'boolean' && a[param] !== 0)) {
                a[param] = '';
            }
            if((!b[param] && typeof b[param] !== 'boolean' && b[param] !== 0)) {
                b[param] = '';
            }

            let first = a[param], second = b[param];
            let altFirst, altSecond;

            if(altParam) {
                altFirst = a[altParam];
                altSecond = b[altParam];

                if(altParam.indexOf('date') !== -1 || altParam.indexOf('time') !== -1) {
                    altFirst = +new Date(a[altParam]);
                    altSecond = +new Date(b[altParam]);
                } else if(typeof a[altParam] === 'string') {
                    altFirst = a[altParam].toLowerCase();
                    altSecond = b[altParam].toLowerCase();
                }
            }

            if(param.indexOf('date') !== -1 || param.indexOf('time') !== -1) {
                first = +new Date(a[param]);
                second = +new Date(b[param]);
            } else if(a[param] == +a[param]) {
                first = +a[param];
                second = +b[param];
            } else if(typeof a[param] === 'string') {
                first = a[param].toLowerCase();
                second = b[param].toLowerCase();
            }

            if(first < second) {
                return -1;
            } else if(second < first) {
                return 1;
            } else {
                if(altParam) {
                    if(altFirst < altSecond) {
                        return -1;
                    } else if(altSecond < altFirst) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            }
        });
    }

    //order descending- start with greatest
    orderByParamDescending(param, group, altParam?)  {
        console.log('orderByParamDescending');
        return group.sort((a, b): number => {
            //account for possible null values - set to empty str if so
            if((!a[param] && typeof a[param] !== 'boolean' && a[param] !== 0)) {
                a[param] = '';
            }
            if((!b[param] && typeof b[param] !== 'boolean' && b[param] !== 0)) {
                b[param] = '';
            }

            let first = a[param], second = b[param];
            let altFirst, altSecond;

            //altParam provides fallback sorting- need to make same checks for date/str as normal param
            if(altParam) {
                altFirst = a[altParam];
                altSecond = b[altParam];

                if(altParam.indexOf('date') !== -1 || altParam.indexOf('time') !== -1) {
                    altFirst = +new Date(a[altParam]);
                    altSecond = +new Date(b[altParam]);
                } else if(typeof a[altParam] === 'string') {
                    altFirst = a[altParam].toLowerCase();
                    altSecond = b[altParam].toLowerCase();
                }
            }

            //properly format sort data (date/string here)
            if(param.indexOf('date') !== -1 || param.indexOf('time') !== -1) {
                first = +new Date(a[param]);
                second = +new Date(b[param]);
            } else if(a[param] == +a[param]) {
                first = +a[param];
                second = +b[param];
            } else if(typeof a[param] === 'string') {
                first = a[param].toLowerCase();
                second = b[param].toLowerCase();
            }

            if(first < second) {
                return 1;
            } else if(second < first) {
                return -1;
            } else {
                if(altParam) {
                    if(altFirst < altSecond) {
                        return -1;
                    } else if(altSecond < altFirst) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            }
        });
    }
}