import {Component, OnInit} from '@angular/core';

import {NotificationService} from './notification.service';

@Component({
    selector: 'notification-component',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
    // template: `
    //         <div class="notification-wrapper {{notificationService.bgType}}"
    //             [ngClass]="{'pushdown': notificationService.isNotificationHidden}" >
    //             <i class="fa fa-times" 
    //                 style="position:absolute;top:0;right:3px;font-size:0.9em;cursor:pointer;" 
    //                 [hidden]=!notificationService.manualDismiss (click)="closeNotice()">
    //             </i>
    //             <div>{{notificationService.message}}</div>
    //             <i style="margin: 0 4px 0 4px;" class="notification-icon fa {{notificationService.iconType}}"></i>
    //         </div>
    // `,
    // styles: [`
    //     .notification-wrapper {
    //         opacity: 1;
    //         position: fixed;
    //         box-sizing: border-box;
    //         bottom: 15px;
    //         right: 15px;
    //         min-width: 250px;
    //         max-width: 50%;
    //         border-radius: 5px;
    //         color: #fff;
    //         display: flex;
    //         justify-content: space-around;
    //         align-items: center;
    //         padding: 12px;
    //         font-size: 1.25em;
    //         -webkit-backface-visibility: hidden;
    //         transform: translateY(0);
    //         transition: transform 0.5s ease;
    //         z-index: 9999;
    //     }
    //     .pushdown {
    //         transform: translateY(200%);
    //     }
    // `]
})
export class NotificationComponent implements OnInit {
    showNotice;

    constructor(
        public notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.showNotice = this.notificationService.showNotification;
        this.notificationService.manualDismiss = false;
        this.notificationService.timeToClose = 3000;
    }

    closeNotice() {
        this.notificationService.isNotificationHidden = true;
        this.notificationService.manualDismiss = false;
    }
}
