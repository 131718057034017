
import CONFIG from '../../config';
import { Injectable, Component, OnInit, OnDestroy } from '@angular/core';
import { Location, DecimalPipe, CurrencyPipe } from '@angular/common';
// import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

// import { MultiColumnGridService } from '../shared/multi-column-grid.service';
import { AuthService } from '../shared/auth.service';
import { AccountService } from '../shared/account.service';
import { BackendService } from '../shared/backend.service';
// import { FilterService } from '../shared/filter.service';
// import { NotificationService } from '../notification/notification.service';
// import { OrderByService } from '../shared/orderby.service';
// import { PaginationService } from '../shared/pagination.service';
// import { SearchService } from '../shared/search.service';
// import { Utilities } from '../shared/utilities.service';
import { SafeUrl,DomSanitizer } from '@angular/platform-browser';

import { Subscription } from 'rxjs/Subscription';
import { DashboardComponent } from './dashboard.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// import { PositionsComponent } from '../menu/positions/positions.component';
@Component({
    selector: 'app-dash-home',
    templateUrl: './dash-home.component.html',
    providers: [
        DecimalPipe,
        // PositionsComponent
    ],
    styles: [`
        .fl-rt: {
            float: right;
        }
        a {
            font-weight:700;
            cursor: pointer;
        }
        .cancel {
            cursor: pointer;
            font-size: 1.2rem;
            opacity: 0.75;
            transition: opacity 0.3s ease;
        }
        .cancel:hover {
            opacity: 1;
        }
        .blueimg{
            color:#24608C;  
            height: 300px; 
            padding-left: 220px;
            text-align: left;
          }

        'textcolour
        {
            text-color:rgb(255, 255, 255);
        }

        .imgalign
        {
            float:right;
            padding:15px;  
            bottom:550px;            
            width:825px; 
            height:315px
        }
        .welcome-logo {
            max-height: 3.5rem;
            border-radius: .5rem;
            max-width: 100%;
        }
        .welcome-card {
            background-color: rgba(0,0,0,0.5);
        }
    `]
})
@Injectable()
export class DashHomeComponent  implements OnInit, OnDestroy {
    currentSettlementAccountSubscription: Subscription;
    FilterByStatus: FormGroup;
    totalEntries: number = 0;
    accounts: any;
    // accountnumber: string;
    customerName;
    scc: any;
    image: SafeUrl | null = null;
    IssuerIDs:any;
    logintype:boolean=false;
    currentSAccount;
    oneissuerID:boolean=false;
    IssuerID:any;
    noimage:boolean=false;
    loginstatus:boolean=true;


    constructor(
        
        public dialog: MatDialog,
        public decimalPipe: DecimalPipe,
        public formBuilder: FormBuilder,
        public backendService: BackendService,
        public authService: AuthService,
        public accountService: AccountService,
        public _backendService: BackendService,
        private sanitizer:DomSanitizer,
        public router:Router

    ) { 
        //super();
        // this.accountnumber = ""; 
    }

    ngOnInit() {

        this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
        console.log(this.currentSettlementAccountSubscription);
        this.currentSAccount = this.accountService.currentSettlementAccount;
        console.log(this.currentSAccount);

        //this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
       // this.getAccountsDropdowns();
        this.FilterByStatus = this.formBuilder.group({ 'isserID': [] });
        this.FilterByStatus.controls['isserID'].setValue(this.accountService.currentSettlementAccount);
        this.customerName = this.authService.CustomerName;
        if(this.authService.LoginType=="Issuer")
        {
            this.logintype=true;
            this.getIssuerIDs();
        }
        else
        {
           // this.logintype=false; 
          //  this.getLogo(this.authService.CustomerID);

            this.logintype=true;
            this.gteHolderaccountID();
        }
        
        if(this.authService.LoginType=="ADVISOR")
        {
            this.loginstatus=false;
        }
        else
        {
            this.loginstatus=true;
        }
        
    }



    gteHolderaccountID()
    {
        console.log(this.authService.UserID);
        this.backendService.getData(`${CONFIG.loginApiUrlBase}/HolderIDs/${this.authService.CustomerID}`)
    .subscribe(response => {

        console.log(response);
        if(response['result'])
        {
            this.IssuerIDs=response['issuerIDs'];
            console.log(this.IssuerIDs.length);
            if(this.IssuerIDs.length==1)
            {
                this.oneissuerID=true;
                console.log(this.IssuerIDs[0]);
                this.FilterByStatus.controls['isserID'].setValue(this.IssuerIDs[0]);
                this.getLogo(this.IssuerIDs[0]);
                this.accountService.AClicked(this.IssuerIDs[0]);
                //this.IssuerID=this.IssuerIDs[0];
                sessionStorage.setItem('singleIssuerID', this.IssuerIDs[0]);
                this.authService.singleIssuerID = sessionStorage.getItem('singleIssuerID');
                sessionStorage.setItem('IssuerCount', this.IssuerIDs.length);
                this.authService.IssuerCount = sessionStorage.getItem('IssuerCount');

            }
            else
            {
                sessionStorage.setItem('IssuerCount', this.IssuerIDs.length);
                this.authService.IssuerCount = sessionStorage.getItem('IssuerCount');
                this.oneissuerID=false;
            }
        }
    });}






    getIssuerIDs()
    {
        this.backendService.getData(`${CONFIG.loginApiUrlBase}/IssuerIds/${this.authService.UserID}`)
        .subscribe(response => {
            console.log(response);
            if(response['result'])
            {
                this.IssuerIDs=response['issuerIDs'];
                console.log(this.IssuerIDs.length);
                if(this.IssuerIDs.length==1)
                {
                    this.oneissuerID=true;
                    console.log(this.IssuerIDs[0]);
                    this.FilterByStatus.controls['isserID'].setValue(this.IssuerIDs[0]);
                    this.getLogo(this.IssuerIDs[0]);
                    this.accountService.AClicked(this.IssuerIDs[0]);
                    //this.IssuerID=this.IssuerIDs[0];
                    sessionStorage.setItem('singleIssuerID', this.IssuerIDs[0]);
                    this.authService.singleIssuerID = sessionStorage.getItem('singleIssuerID');
                    sessionStorage.setItem('IssuerCount', this.IssuerIDs.length);
                    this.authService.IssuerCount = sessionStorage.getItem('IssuerCount');

                }
                else
                {
                    sessionStorage.setItem('IssuerCount', this.IssuerIDs.length);
                    this.authService.IssuerCount = sessionStorage.getItem('IssuerCount');
                    this.oneissuerID=false;
                }
            }
        });
    }
    filterAuditLog() {
        
        this.scc = this.FilterByStatus.controls['isserID'].value;
        console.log(this.FilterByStatus.controls['isserID'].value);
        this.getLogo(this.scc);
        this.accountService.AClicked(this.scc);
        this.accountService.setSAccount(this.scc);
        
    }
    getLogo(value) {
        this.noimage=false;
        console.log("logo selection");
        const mediaType = 'application/image';
        this.backendService.download(value).subscribe(value => {
            const blob = new Blob([value], { type: mediaType });
            const unsafeImg = URL.createObjectURL(blob);
            this.image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
        }, error1 => {
            this.noimage=true;
            console.log("download error");
        });
    }
    ngOnDestroy() 
    {
        console.log("Testdestorydash home *** Destory");
        // this.currentSAccount.unsubscribe();
        console.log(this.currentSAccount);
        //this.accountService.AClicked("");
        //this.accountService.setSAccount("");
        //if(this.currentSettlementAccountSubscription) { this.currentSettlementAccountSubscription.unsubscribe(); }
    }
    currentSettlementAccountChange(value){
        // this.activeSettlementAccount = value;
        console.log(this.FilterByStatus.controls['isserID'].value);
        this.FilterByStatus.controls['isserID'].setValue(value);
    }

       resetAuditListing() {

        this.FilterByStatus.controls['accountNumber'].setValue('Filter by Settlement Account');

    }
    getValue(item){
        if (item === 'Filter by Settlement Account'){
            return '';
        } else {
            let stringToSplit = item;
            let x = stringToSplit.split("_");
            
            return x[0];
        }
    }

}



// import CONFIG from '../../config';
// import { Component, OnInit } from '@angular/core';
// import { Location, DecimalPipe, CurrencyPipe } from '@angular/common';
// import { Router } from '@angular/router';
// import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

// import { MultiColumnGridService } from '../shared/multi-column-grid.service';
// import { AuthService } from '../shared/auth.service';
// import { BackendService } from '../shared/backend.service';
// import { FilterService } from '../shared/filter.service';
// import { NotificationService } from '../notification/notification.service';
// import { OrderByService } from '../shared/orderby.service';
// import { PaginationService } from '../shared/pagination.service';
// import { SearchService } from '../shared/search.service';
// import { Utilities } from '../shared/utilities.service';

// import { Subscription } from 'rxjs/Subscription';

// @Component({
//     selector: 'app-dash-home',
//     templateUrl: './dash-home.component.html',
//     providers: [DecimalPipe],
//     styles: [`
//         .fl-rt: {
//             float: right;
//         }
//         .greenheader {
//             background-color: #149d65;
//             color:white;
//             font-weight:700;   
//         }
//         .hwidth {
//             width:200px;
//         }
//         a {
//             font-weight:700;
//             cursor: pointer;
//         }
//         .cancel {
//             cursor: pointer;
//             font-size: 1.2rem;
//             opacity: 0.75;
//             transition: opacity 0.3s ease;
//         }
//         .cancel:hover {
//             opacity: 1;
//         }
//     `]
// })
// export class DashHomeComponent extends MultiColumnGridService implements OnInit {
//     loadingData: boolean = false;
//     active: string = '';
    

//     constructor(
//         public location: Location,
//         public backendService: BackendService,
//         public authService: AuthService,
//         public notificationService: NotificationService,
//         public formBuilder: FormBuilder,
//         public searchService: SearchService,
//         public orderByService: OrderByService,
//         public router: Router,
//         public paginationService: PaginationService,
//         public filterService: FilterService,
//         public numberPipe: DecimalPipe,
//         public currencyPipe: CurrencyPipe
//     ) {
//         super(location, backendService, authService, notificationService, 
//         searchService, orderByService, router, paginationService, currencyPipe);
//     }

//     ngOnInit() {        
//         this.searchArray = ['customerID','securityID', 'transactionNumber', 'currentShares', 'ownershipDate', 'activityStatus', 'originOfShares', 'costBasis', 'originalCost'];

//         this.defaultSortOrder = 'ownershipDate';

//         //method on multi-column-grid.service.ts to get data and initialize sort/filter functions
//         /*
//             Passing in callback, as this component needs to do some custom formatting for data display (using angular pipes).  Yes- it's easier to do this in the template, but then the data passed to the search function doesn't match the data on display in the grid, causing user confusion.
//         */
//         this.serviceInit(this.formatDataForSearch);
//     }

//     ngOnDestroy() {
//         this.orderByService.resetService();
//         this.allSubscription.unsubscribe();
//         this.entryRangeSubscription.unsubscribe();
//     }

//     formatDataForSearch(data) {
//         data.map(d => {
//             //loop through and convert specific items that need it- others remain as-is
//             d.rate = this.numberPipe.transform(d.rate, '1.4-4');
//             d.originalCost = this.numberPipe.transform(d.originalCost, '1.2-2');
//             d.costBasis = this.numberPipe.transform(d.costBasis, '1.2-2');
//             d.currentShares = this.currencyPipe
//                 .transform(d.currentShares, 'USD', false, '4.2-2')
//                 .slice(3);
//         });
//     }
// }
