import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ResetFormService } from './reset-form.service';
import { NotificationService } from '../notification/notification.service';

@Component({
    templateUrl: './password-resend.component.html',
    styles: [`
        .field-bottom-right {
            position: absolute;
            bottom: 12px;
            right: 0;
            z-index: 99;
        }
    `],
    selector: 'forgot-password'
})

export class PasswordResendComponent implements OnInit{
    result:any;
    msg : any;
    forgotPassword: FormGroup;
    submitted1: boolean = false;
    submitted2: boolean = false;
    resetResponse: any;

    constructor(
        public _router: Router,
        public _formBuilder: FormBuilder,
        public _resetFormService: ResetFormService,
        public _notificationService: NotificationService
    ) {
        this.setForm();
    }

    ngOnInit() {
     
    }

    checkEmail(email: any) {
        console.log(email);
        this.submitted1 = true;
        if(this.forgotPassword.controls['Email'].valid) {
            this._resetFormService.submitEmail(email);
        }
    }

    //Get list of users by email- if user exists, send email (backend processed)
    sendResetEmail(submittedForm) {

        console.log(submittedForm); 
        this.submitted2 = true;

        if (this.forgotPassword.valid) {
            this.submitted2 = false;
            this._resetFormService.submitAnswer(submittedForm)
                .subscribe(resetResponse => {
                    if (resetResponse) {
                        this._notificationService.showNotification({ message: resetResponse['msg'], type: 'success' });
                        this._resetFormService.forgottenPasswordFormUpdate(false);
                        this.forgotPassword.reset();
                        this._router.navigate(['']);
                    } else {
                        this._notificationService.showNotification({ message: resetResponse['msg'], type: 'error' });
                        this.forgotPassword.controls['Answer'].reset();
                        this._router.navigate(['/login']);
                    }
                },
                error => {
                    console.log(error);
                    this._notificationService.showNotification({ message: 'Server Error', type: 'error' });
                    this._router.navigate(['/login']);
                });
        } else {
            //validators handle this
            console.log('Invalid form submission');
        }
        
    }

    setForm() {
        this.forgotPassword = this._formBuilder.group({
            'Email': ['', Validators.required],
           'Answer': ['', Validators.required]
        });
    }
}
