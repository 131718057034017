import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from 'app/notification/notification.service';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import { Utilities } from 'app/shared/utilities.service';
import { CurrencyPipe, Location } from '@angular/common';
import { MultiColumnGridService } from 'app/shared/multi-column-grid.service';
import { SearchService } from 'app/shared/search.service';
import { OrderByService } from 'app/shared/orderby.service';
import { PaginationService } from 'app/shared/pagination.service';
import CONFIG from 'config';
import { DocumentsComponent } from '../documents.component';
import { HttpClient } from '@angular/common/http';
import { AccountService } from 'app/shared/account.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-docummentscontainer',
  templateUrl: './docummentscontainer.component.html',
  styleUrls: ['./docummentscontainer.component.css']
})
export class DocummentscontainerComponent implements OnInit {

  currentSettlementAccountSubscription: Subscription;
  currentSettlementAccount = '';
  activeSettlementAccount = 'refresh';
  currentRoute: any;  
  hidemenu:boolean=false;

  constructor(public _formBuilder: FormBuilder,
    public router: Router,
    public _location: Location,
    public notificationService: NotificationService,
    public authService: AuthService,
    public backendService: BackendService,
    public dialog: MatDialog,
    public searchService: SearchService,
    public orderByService: OrderByService,
    public paginationService: PaginationService,
    public currencyPipe: CurrencyPipe

  ) {

    // super(_formBuilder,router,_location,notificationService,backendService,authService,dialog,
    //   searchService,orderByService,paginationService,currencyPipe);

    router.events.filter(event => event instanceof NavigationEnd)
      .subscribe(event => { 
        this.currentRoute = event['url'];       
      });
  }

  ngOnInit() 
  {
    if(this.authService.LoginType=="Issuer")
    {
      this.hidemenu=true;
    }
    else
    {
      this.hidemenu=false;
    }
  }

  btnRefresh() {
    if (this.currentRoute == "/dashboard/document") {
      this.router.navigate(['/dashboard/document/documents']);
    }
    else {
      this.router.navigate(['/dashboard/document']);
    }
  }
}
