import { Component, OnInit } from '@angular/core';
import { MatDialog , MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//import { AuthService } from '../shared/auth.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-popupdisplay',
  templateUrl: './popupdisplay.component.html',
  styleUrls: ['./popupdisplay.component.scss']
})
export class PopupdisplayComponent implements OnInit {

  
  constructor(public dialogRef: MatDialogRef<PopupdisplayComponent>,
    //public _authService: AuthService,
    public _router:Router) 
  { 

  }
  public confirmMessage:string;
  public displaygrid:boolean;
  ngOnInit()
  {
   console.log(this.displaygrid);
  }
  // onClickyes(value)
  // {
  //               this._router.navigate(['/staffdashboard/forexsale']);
  //               localStorage.setItem('yescase',value);
  //               this._authService.yescase=localStorage.getItem('yescase');
  //               console.log(this._authService.yescase);
  //               console.log(value);
                
  // }
}



