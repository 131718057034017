import CONFIG from '../../config';

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Headers, RequestOptions } from '@angular/http';

import { NotificationService } from '../notification/notification.service';
import { AuthService } from './auth.service';
import { BackendService } from './backend.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map, catchError , debounce} from 'rxjs/operators';
import { of } from 'rxjs';
import { Console } from 'console';

@Injectable()
export class Utilities {
    focused;
   // result:any;
    msg : any;
    checkvalue:any;
    
    constructor(
        public _formBuilder: FormBuilder,
        public _router:Router,
        public location: Location,
        public notificationService: NotificationService,
        public authService: AuthService,
        public backendService: BackendService,
        public dialog: MatDialog
    ) {}

    //display server error- if due to jwt expiration, log out and redirect to login screen
    serverErrorOrTimeout(error: string): void {
        this.notificationService.showNotification({ timer: 5000, message: error, type: 'error', closeable: false });

        //if error is due to session time out, log the user out
        if(error === 'Session Expired- please log in') {
            this.authService.logout();
        }
    }

    //when sending jwt to server for data, check status (admin vs non admin, user key for profile, etc)
    checkLoginStatus(): Observable<any> {
        //return an observable so end function can subscribe and perform actions based on result after it comes in
        //return this.backendService.getData(`${CONFIG.loginApiUrlBase}/checklogin`);
        return Observable.of(true);
    }

    checkLoginForAuthGuard(): Observable<boolean> {
        console.log("check login"+sessionStorage.getItem('partial_login'));
  
        if(sessionStorage.getItem('partial_login') === 'true') {
             
            this.notificationService.showNotification({ 
                timer: 5000, message: 'Please finish security process', 
                type: 'error', closeable: false 
            });
            return Observable.of(false)
                .do(() => {                   
                    return this.authService.logout();
                });
        } else {
            console.log("Check login utilities");
            return this.backendService.getData(`${CONFIG.loginApiUrlBase}/checklogin`).pipe(
            map(response => {
                console.log(response);
                //console.log(response.result);
                //update shared 'loggedIn' variable that maintains state while in app                  
                this.checkvalue = response['result'];
                this.authService.loggedIn =true;
                //update user_type variable that checks user's admin vs employee status                 
                return this.checkvalue;
            }))
            //catchError(err => of(this.serverErrorOrTimeout(err))),
            
            // .catch(error => {
            //     return Observable.throw(this.serverErrorOrTimeout(error));
            // });      
      }    
    }

    //all purpose cancel and return (if passed with nothing, just shows 'update canceled')
    cancel(msg='Update Canceled', type='warning'): void {
        this.location.back();
        this.notificationService.showNotification({
            timer: 2000,
            message: msg,
            type: type
        });
    }
   


}
