import CONFIG from '../../config';

import { Injectable } from '@angular/core';
import { BackendService } from '../shared/backend.service';
import { NotificationService } from '../notification/notification.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ResetFormService {
    forgotPasswordShow: boolean = false;
    validEmail: boolean = false;
    emailPending: boolean = false;
    validAnswer: boolean = false;
    pwd_reset_q1: string = '';
    pwd_reset_q2: string = '';
    questions: string[] = [];
    selectedQuestion: number = 0; //index of questions array of selected question

    constructor(public _backendService: BackendService, public _notificationService: NotificationService) {}

    forgottenPasswordFormUpdate(status: boolean, $event?: any) {
        console.log("testtt");
        if($event) { $event.preventDefault(); };
        this.validEmail = false;
        this.validAnswer = false;
        this.forgotPasswordShow = status;
    }

    switchQuestion() {
        console.log("testtt");
        this.selectedQuestion = this.selectedQuestion === 0 ? 1 : 0;
    }

    submitEmail(email: string) {
        console.log("testtt");
        this.emailPending = true;
        this._backendService.postData(email, `${CONFIG.sendemailApiUrlBase}/checkcustomer`)
            .subscribe(response => {
                console.log(response);
                this.emailPending = false;
                if(response['result']) {
                    //if user hasn't set reset questions yet, don't allow
                    if(!response || !response) {
                        this._notificationService.showNotification({ 
                            message: 'No password reset questions are on file for your login - Please contact your site administrator to reset your password.', 
                            type: 'error' 
                        });
                        this.forgotPasswordShow = false;
                        return;
                    }
                    this.questions.push( response['pwd_reset_q1'],response['pwd_reset_q2']);
                    this.validEmail = true;
                } else {
                    this._notificationService.showNotification({ message: 'Invalid CustomerID', type: 'error' });
                    this.validEmail = false;
                }
            });
    }

    submitAnswer(answerObj: Object) {
        console.log("testtt");
        //add the text of the selected question to the object sent to the server
        answerObj['Question'] = this.questions[this.selectedQuestion];

        return this._backendService.postData(answerObj, `${CONFIG.sendemailApiUrlBase}/email`).pipe(
             map(response => {
                return response;
            }))
            // .catch(this.handleError)
            // .finally(() => {
            //     this._loadingService.hide();
            // });
    }

    disableUser(email: string) {
        console.log("testtt");
        this._backendService.postData(email, `${CONFIG.usersApiUrlBase}/disableuser`)
            .subscribe(response => {
                this._notificationService.showNotification({ message: response['msg'], type: 'success' });
            }, error => {
                this._notificationService.showNotification({ message: error.msg, type: 'error' });
            });
    }
}