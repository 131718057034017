import {Component} from '@angular/core';

import {LoadingService} from './loading.service';

@Component({
    selector: 'loading-component',   
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
    // template: `
    //     <div class="fullscreen full-transparent" [hidden]="loadingService.loaderHidden">
    //         <img src="../../assets/img/STC-Logo-2018-200px.png" alt="STC Logo" />
    //         <div class="loader">Loading...</div>
    //     </div>
    // `,
    // styles: [`
    //     .fullscreen {
    //         position: fixed;
    //         top: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         z-index: 9999;
    //         background-color:#ffffff;
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         justify-content: center;
    //     }
    // `]
})

export class LoadingComponent {
    constructor(public loadingService: LoadingService) { }
}
