import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { PaginationService } from './pagination.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'pagination',
    template: `
        <mat-paginator [length]="_paginationService.totalEntries"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = $event; _paginationService.updatePage($event.pageIndex,$event.pageSize);">
        </mat-paginator>
        `,
})
export class PaginationComponent implements OnInit, OnDestroy {
    firstEntryOnScreen: number = 0;
    lastEntryOnScreen: number = 0;
    entryRange: Subscription;
    // MatPaginator Inputs
    length;
    pageSize = 10;
    pageSizeOptions = [5, 10, 25, 100];

    // MatPaginator Output
    pageEvent: PageEvent;

    constructor(public _paginationService: PaginationService) {}

    ngOnInit() {
        //be sure variables are reset in case service is shared with another screen
        this._paginationService.totalEntries = 0;
        this.firstEntryOnScreen = 0;

        this.entryRange = this._paginationService.entryRange
            .subscribe(range => {
                this.firstEntryOnScreen = range[0];
                this.lastEntryOnScreen = range[1];
            });
    }

    ngOnDestroy() {
        this.entryRange.unsubscribe();
    }
}