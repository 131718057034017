import { Component, OnInit } from '@angular/core';
import {  FormBuilder,FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../../shared/backend.service';
import CONFIG from '../../../config';
import { AuthService } from '../../shared/auth.service';
import { Utilities } from '../../shared/utilities.service';
import { Injectable } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { Headers, RequestOptions } from '@angular/http';

import { NotificationService } from '../../notification/notification.service';

import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';  

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
//   template: `<pdf-viewer [src]="pdfSrc" 
//   [render-text]="true"
//   style="display: block;"
// ></pdf-viewer>`,
 // styleUrls: ['./report-viewer.component.css'],
  providers: [DatePipe]
})
export class ReportViewerComponent extends Utilities implements OnInit {
  DateFilter: FormGroup;
  Filenameuser: any;
  loadingData: boolean = false;
 // pdfSrc ="https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  pdfSrc ="../assets/reports/ASL016007Report.pdf";


     constructor(
      public _router:Router,
      public location: Location,
      public notificationService: NotificationService,
      public _formBuilder: FormBuilder,
      public backendService: BackendService,
      public authService: AuthService,public dialog: MatDialog,
      public datapipe:DatePipe) {
      super(_formBuilder, _router, location, notificationService, authService, backendService, dialog,);
    }

  ngOnInit(): void {
    this.loadingData = true;
    console.log(this.loadingData);
    this.openFile();
  }

  filename()
  {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    var d = new Date();
    var h = addZero(d.getHours());
    var m = addZero(d.getMinutes());
    var s = addZero(d.getSeconds());

    //this.Filenameuser="RestrictedVersion3_"+this.authService.CustomerID+"_"+ "_" + h + "-" + m + "-" + s + ".pdf";

    console.log(this.Filenameuser);
  }

print(value)
{
  this.loadingData = false;
  value.RptDate=this.datapipe.transform(value.asofdate,"MM/dd/yyyy");
  //value.enddate=this.datapipe.transform(value.enddate,"MM/dd/yyyy")

  console.log(value,`${CONFIG.reportApiUrlBase}/${this.authService.CustomerID}`)
  this.backendService.postData(value,`${CONFIG.reportApiUrlBase}/${this.authService.CustomerID}/${this.authService.LoginType}`)
  .subscribe(response =>{
    console.log(response);
    if(response['result'])
    {
      this.loadingData = true;
      console.log(response);
      this.Filenameuser=response["value"];
      this.openFile();
    }
    else
    {
      this.notificationService.showNotification({ type: 'error', message: response['msg'] });
    }
     
     console.log(response)
         //this.filename();
  //  this.openFile();

  }
  )
 

}

openFile() {
  console.log("test",this.authService.backendresponse,this.authService.Filename);

  if(this.authService.backendresponse=="true")
    this.loadingData=true;
  else
    this.loadingData=false;
  this.pdfSrc ="../assets/reports/"+this.authService.Filename;
  //window.open("../assets/reports/"+this.Filenameuser);
}

  // setForm() {       
  //   this.DateFilter = this._formBuilder.group({
  //       'asofdate': ['', Validators.required],
  //       //'enddate': ['', Validators.required],
        
        
  //   });

    
//}
}
