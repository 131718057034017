import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PopupdisplayComponent } from '../popupdisplay/popupdisplay.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import CONFIG from 'config';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { NotificationService } from '../../notification/notification.service';
import { catchError, map, debounce, timeout } from 'rxjs/operators';
import { debounceTime } from 'rxjs-compat/operator/debounceTime';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { AccountService } from '../../shared/account.service';
import { MatRadioChange } from '@angular/material/radio';
interface Report {
  disabled?: boolean;
  value: string;
  viewValue: string;

}

interface ReportGroup {
  disabled?: boolean;
  name: string;
  report: Report[];
}
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  form: FormGroup;
  TextSearch: FormGroup;
  groupList: any = [];
  multiplecheckvalues: any;
  loadingData: boolean = false;
  loadingData1: boolean = false;
  reportGenerating: boolean = false;
  reportControl = new FormControl();
  HolderId = new FormControl();
  HolderShortName = new FormControl();
  RestrictCode = new FormControl();
  optionalFilters: FormGroup;

  date = new FormControl(new Date());
  begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() - 30))));
  enddate = new FormControl(new Date());

  securityID = new FormControl();
  holderID = new FormControl();
  shortname = new FormControl();

  AdvisorID = new FormControl();
  Advisorname = new FormControl();
  reportGroups: ReportGroup[] = [
    // {
    //   name: 'Equity Holders',
    //   report: [
    //     { value: 'Security Holder Listing', viewValue: 'Security Holder Listing' },
    //     { value: 'Shareholders with Certificate Detail', viewValue: 'Shareholders with Certificate Detail' },
    //     { value: 'FinancialAdvisor Report', viewValue: 'Financial Advisor Report' },
    //     { value: 'Sponsor Report', viewValue: 'Sponsor Report' }
    //   ]
    // },
    {
      name: 'Equity Holders',
      report: [
        { value: 'Shareholders Detail Holdings', viewValue: 'Shareholders Detail Holdings' },
        { value: 'Shareholders Summary Holdings', viewValue: 'Shareholders Summary Holdings' },
        { value: 'FinancialAdvisor Report', viewValue: 'Financial Advisor Report' },
        //{ value: 'Sponsor Report', viewValue: 'Sponsor Report' }
      ]
    },
    // {
    //   name: 'Equity Holdings',
    //   report: [
    //     { value: 'Shareholder with Summary Share Holdings', viewValue: 'Shareholder with Summary Share Holdings' },
    //     { value: 'Statements', viewValue: 'Statements' },
    //     { value: 'Certificates Issued', viewValue: 'Certificates Issued' },
    //     { value: 'Shares in Plan by Shareholder', viewValue: 'Shares in Plan by Shareholder' },
    //     { value: 'List of Restricted Certificates', viewValue: 'List of Restricted Certificates' }
    //   ]
    // },
    {
      name: 'Equity Holdings',
      report: [
        //{ value: 'Shareholder with Summary Share Holdings', viewValue: 'Shareholder with Summary Share Holdings' },
        { value: 'Statements', viewValue: 'Statements' },
        { value: 'Certificates Issued', viewValue: 'Certificates Issued' },
        //{ value: 'Shares in Plan by Shareholder', viewValue: 'Shares in Plan by Shareholder' },
        { value: 'List of Restricted Certificates', viewValue: 'List of Restricted Certificates' }
      ]
    },
    //Commented by ANIL as per Mallik
    {
      name: 'Equity Transactions',
      disabled: true,
      //,  disabled: true 
      report: [
        { value: 'Transaction Journal', viewValue: 'Transaction Journal', },
        { value: 'Confirmation', viewValue: 'Confirmation', disabled: true },
        { value: 'Transactions with Cost Basis Details', viewValue: 'Transactions with Cost Basis Details' },
        { value: 'Cash Disbursements (Cash Div, Cash in Lieu, Interest)', viewValue: 'Cash Disbursements (Cash Div, Cash in Lieu, Interest)' }
      ]
    },
    // {
    //   name: 'Proxy',
    //   disabled: false,
    //   report: [
    //     { value: 'Issues and Director Names', viewValue: 'Issues and Director Names' },
    //     { value: 'Contact List of Shareholders who have not Voted', viewValue: 'Contact List of Shareholders who have not Voted' },
    //     { value: 'Total Vote', viewValue: 'Total Vote' }
    //   ]
    // },
    {
      name: 'Proxy',
      disabled: false,
      report: [
        { value: 'Proxy Voting Summary', viewValue: 'Proxy Voting Summary' },
        { value: 'Proxy Voting Detail', viewValue: 'Proxy Voting Detail' },
        //{ value: 'Total Vote', viewValue: 'Total Vote' }
      ]
    },
    // {
    //   name: 'Bonds',
    //   report: [
    //     { value: 'Transaction Journal', viewValue: 'Transaction Journal' },
    //     { value: 'Instrument Register', viewValue: 'Instrument Register' },
    //     { value: 'Corporate Statement', viewValue: 'Corporate Statement' },
    //   ]
    // },
    {
      name: 'Bonds',
      report: [
        { value: 'Bond Transaction Journal', viewValue: 'Bond Transaction Journal' },
        { value: 'Bondholders Detail Holdings', viewValue: 'Bondholders Detail Holdings' },
        { value: 'Bondholders Summary Holdings', viewValue: 'Bondholders Summary Holdings' },
        { value: 'Bond Financial Advisor Report', viewValue: 'Bond Financial Advisor Report' },
      ]
    },

    // {
    //   name: 'Options and Warrants',
    //   report: [
    //     { value: 'Stock Options and Warrants Schedule', viewValue: 'Stock Options and Warrants Schedule' },
    //     { value: 'Options and Warrants Outstanding', viewValue: 'Options and Warrants Outstanding' },
    //   ]
    // },
    {
      name: 'Options and Warrants',
      report: [
        { value: 'Warrants Outstanding', viewValue: 'Warrants Outstanding' },
        { value: 'Options Outstanding', viewValue: 'Options Outstanding' },
      ]
    },


  ];
  allFiltered:any;
  OrginalHolderNames1:any;
  OrginalHolderNames: any;
  selectedActivityOptions: string;
  activityOptions: string[] = ['Active Shares', 'Inactive Shares', 'Both'];
  Filenameuser: any;
  customerNames: any;
  transactiontypes: any;
  HolderIds: any;
  backupHolderIds: any;
  backupHoldernames: any;
  HolderShortNames: any;
  AdvisorShortNames: any;
  AdvisorIDSShortNames: any;
  SecIDs: any;
  RestrictCodes: any;
  AdvisorIdList: any;
  filtercsutomerid: any;
  filtershortname: any;
  test: any;
  HolderIdsHolderShortNames: any;
  Summary: boolean = true;
  Holdings: boolean = true;
  CostBasis: boolean = true;
  Activity: boolean = true; PendingCerts: boolean = true; 
  IncludeBTransNos: boolean = true;
  ExcludeLostHolders: boolean = false
  filename: any;
  holderid: any;
  currentDate: any;
  Printoption = new FormControl('PDF');
  Exceloption = new FormControl('Detail');
  currentSAccount;
  currentSettlementAccountSubscription: Subscription;
  maxDate = new Date();
  minDate = new Date();
  maxDate1 = new Date();
  issuerid: any;
  transactiontypecheckvalue: any;
  transactiontype: FormArray;
  reportId;
  constructor(
    public dialog: MatDialog,
    public _router: Router,
    public authService: AuthService,
    public backendService: BackendService,
    public accountService: AccountService,
    public _datepipe: DatePipe,
    public _formBuilder: FormBuilder,
    public notificationService: NotificationService, private _http: HttpClient,
    private route: ActivatedRoute,
  ) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.reportId = params.id;
      // console.log(params.id);
      // console.log('CHANGE');
      // console.log('reportId:  ' + this.reportId);
      if (params.id === "print") {
        this.reportControl.setValue('');
        this.setForm();
        console.log('RESET');
      };
    });
    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
    console.log(this.currentSettlementAccountSubscription);
    this.currentSAccount = this.accountService.currentSettlementAccount;
    console.log(this.currentSAccount);

    if (this.authService.IssuerCount == "1")
      this.issuerid = this.authService.singleIssuerID;
    else
      this.issuerid = this.currentSAccount;


    console.log(this.issuerid, this.authService.IssuerCount);
    this.setForm();
    this.getOptionalFilters();
    this.form = this._formBuilder.group({
      transactiontype: this._formBuilder.array([], [Validators.required])
    })
    this.TextSearch = this._formBuilder.group({
      search: ['']
    })

  }
  ngDoCheck() {
   // this.HolderShortNames = this.groupList;
  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;

  }

  getOptionalFilters() {
    this.loadingData = true;
    this.backendService.getData(`${CONFIG.reportApiUrlBase}/${this.issuerid}`)
      .subscribe(response => {
        console.log(response);
        this.loadingData = true;
        this.HolderIds = response["holderid"];
        this.backupHolderIds = response["holderid"];
        this.HolderShortNames = response["shortname"];
        this.backupHoldernames = response["shortname"];
        this.SecIDs = response["secid"];
        this.RestrictCodes = response["restrict"];
        this.AdvisorIdList = response['advisorList'];
        this.AdvisorShortNames = response['advisorListName'];
        this.AdvisorIDSShortNames = response['advisoridshortname'];
        this.HolderIdsHolderShortNames = response['holderidshortname'];
        this.customerNames = response['customernames'];
        this.transactiontypes = response['transactiontypes'];
        //console.log(this.transactiontypes);
        // console.log(this.HolderIdsHolderShortNames.customerName);

        // this.transactiontype = this.form.get('transactiontype') as FormArray;

        // for(var i=0; i<this.transactiontypes.length; i++){
        //   this.transactiontype.push(new FormControl(this.transactiontypes[i]));
        // }
        console.log(this.HolderIdsHolderShortNames);

        for (var i = 0; i < this.HolderIdsHolderShortNames.length; i++) {
          var test = this.HolderIdsHolderShortNames[i].customerID + ": " + this.HolderIdsHolderShortNames[i].shortName + ": " + this.HolderIdsHolderShortNames[i].customerName;
        // var test = this.HolderIdsHolderShortNames[i].shortName + ": " + this.HolderIdsHolderShortNames[i].customerName;
          //var test= this.HolderIdsHolderShortNames[i].shortName;
          this.groupList.push(test);
        }

        this.HolderShortNames = this.groupList;
        this.OrginalHolderNames=this.HolderShortNames;
      });
  }


  onKey(value) { 
    console.log(value);
    //this.HolderShortNames = this.search(value);
    this.search(value)
    }
    // Filter the HoldershortNames list and send back to populate the HoldershortNames**
   search(value: string) { 
    let filter = value.toLowerCase();
  //  console.log(this.OrginalHolderNames1)
    this.allFiltered=this.OrginalHolderNames.filter(option => option.toLowerCase().includes(filter));
    console.log( this.allFiltered)

    if (value.length > 0) {
        this.HolderShortNames = this.allFiltered;
        console.log(this.HolderShortNames)
        //this.searchFilteredItems = allFiltered;
      } else {
        this.HolderShortNames = this.OrginalHolderNames;
        console.log(this.HolderShortNames)
      }
   }
  

  btnbackreport() {
    console.log("calling setForm()");
    this._router.navigate(['/dashboard/reports/print']);
    // this.setForm(); *** taken care of by route navigation
    this.HolderIds = this.backupHolderIds;
    this.HolderShortNames = this.backupHoldernames;
    this.Summary = true;
    this.Holdings = true;
    this.CostBasis = true;
    this.Activity = true;
    this.HolderShortNames = this.groupList;
    this.TextSearch.controls['search'].setValue('');
    this.Printoption=new FormControl('PDF');
    console.log(this.Printoption.value)
  }
  btnstartreport(viewValue) {
    this.reportControl.setValue(viewValue);
    this._router.navigate(['/dashboard/reports/' + viewValue]);
    // console.log("CLICK!");
    // console.log("viewValue:  " +this.reportControl.value);

    if (this.reportControl.value == 'Statements' || this.reportControl.value == 'Cash Disbursements (Cash Div, Cash in Lieu, Interest)') {
      // this.begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() - 365))));
      // this.enddate = new FormControl(new Date());
      this.maxDate = new Date(new Date().setDate(new Date().getDate()));
      this.minDate = new Date(new Date().setDate(new Date().getDate() - 365));


      this.begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() - 30))));
      this.enddate = new FormControl(new Date());
      this.maxDate1 = new Date(new Date().setDate(new Date().getDate() - 30));

    };
  }
  chbegindate() {
    //Commeneted mim and max date
    // if(this.reportControl.value=='Statements' || this.reportControl.value=='Cash Disbursements (Cash Div, Cash in Lieu, Interest)')
    // {
    // console.log(this.begindate.value);
    // this.minDate = new Date(new Date(this.begindate.value.getFullYear(),this.begindate.value.getMonth(),this.begindate.value.getDate()));
    // this.maxDate=new Date(new Date(this.begindate.value.getFullYear(),this.begindate.value.getMonth(),this.begindate.value.getDate()+365));
    //  this.enddate=new FormControl(this.maxDate);
    // console.log(this.maxDate,this.minDate);
    // }
  }

  onCheckboxChange(e) {
    this.transactiontype = this.form.get('transactiontype') as FormArray;
    //int i;

    console.log(this.transactiontype.value);
    console.log(e.target.value);
    if (e.target.checked) {
      this.transactiontype.push(new FormControl(e.target.value));
    } else {
      const index = this.transactiontype.controls.findIndex(x => x.value === e.target.value);
      this.transactiontype.removeAt(index);

      // this.myArray.filter(item => item !== obj);
    }
    // console.log(transactiontype.value);

    this.multiplecheckvalues = this.transactiontype.value;
    console.log(this.multiplecheckvalues);

    //   const transactiontype: FormArray = this.form.get('transactiontype') as FormArray;
    // const trantype: FormArray = this.transactiontypes;
    // console.log(e);
    //   if (e.target.checked) {
    //     transactiontype.push(new FormControl(e.target.value));
    //   } else {
    //      const index = transactiontype.controls.findIndex(x => x.value === e.target.value);
    //      transactiontype.removeAt(index);
    //   }
    //  // console.log(transactiontype.value);

    //   this.multiplecheckvalues = transactiontype.value;
    //   console.log(this.multiplecheckvalues, trantype);

  }


  chSummary(value) {
    console.log(value);
    this.Summary = value.checked;
  }
  chHoldingsch(value) {
    this.Holdings = value.checked;
  }
  chCostBasis(value) {
    this.CostBasis = value.checked;
  }
  chActivity(value) {
    this.Activity = value.checked;
  }
  chPendingCerts(value) {
    this.PendingCerts = value.checked;
  }
  chSelectedDateTransactions(value) {
    this.IncludeBTransNos = value.checked;
  }

  chSelectedExcludeLostHolders(value) {
    console.log(value);
    this.ExcludeLostHolders = value.checked;
  }

  radioChange($event: MatRadioChange) {
    console.log($event.source.name, $event.value);
    //this.SystemId=true;
    if ($event.value === 'PDF') {
        // Do whatever you want here
        this.Printoption.value=='PDF'
     }
    else{
      this.Printoption.value=='Excel'
       }

    }


  printReport(value) {
    console.log(value);
    console.log(this.Printoption.value);
    console.log(this.Exceloption.value);
    console.log(this.reportControl.value, this.authService.LoginType);
    this.loadingData = false;
    this.loadingData1 = true;
    value = {};

    value.PendingCerts = this.PendingCerts;

    if (value.PendingCerts == undefined)
      value.PendingCerts = false;


      value.IncludeBTransNos = this.IncludeBTransNos;

      if (value.IncludeBTransNos == undefined)
        value.IncludeBTransNos = false;
  
        value.ExcludeLostHolders = this.ExcludeLostHolders;

        if (value.ExcludeLostHolders == undefined)
          value.ExcludeLostHolders = false;


    if (this.reportControl.value != "Statements") {
      this.Summary = false;
      this.Holdings = false;
      this.CostBasis = false;
      this.Activity = false;
    }

    if (this.reportControl.value == "Shareholders Detail Holdings") {
      this.filename = "HolderCertificateDetails";
      value.RptName = "HolderCertificateDetails";
      value.RptMenu = this.reportControl.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
       value.PrintType=this.Printoption.value;
       console.log(value.PrintType)
    }
    else if (this.reportControl.value == "Shareholders Summary Holdings") {
      this.filename = "ShareholderWithSummaryDetail";
      value.RptName = "ShareholderWithSummaryDetail";
      value.RptMenu = this.reportControl.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
     // value.ExcelType =null;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
        value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == "Financial Advisor Report") {
      this.filename = "FinancialAdvisorReport";
      value.RptName = "FinancialAdvisorReport";
      value.RptMenu = this.reportControl.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      value.AdvisorID = this.AdvisorID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == "Statements") {
      this.filename = "TAStatement";
      value.Summary = this.Summary;
      value.Holdings = this.Holdings;
      value.CostBasis = this.CostBasis;
      value.Activity = this.Activity;

      if (value.Summary == undefined)
        value.Summary = false;
      if (value.Holdings == undefined)
        value.Holdings = false;
      if (value.CostBasis == undefined)
        value.CostBasis = false;
      if (value.Activity == undefined)
        value.Activity = false;

      value.RptName = "TAStatement";
      value.RptMenu = this.reportControl.value;
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      this.holderid = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
       value.PrintType=this.Printoption.value;

      console.log(value.securityID);
    }

    else if (this.reportControl.value == "Certificates Issued") {
      this.filename = "HolderCertificateDetails";
      value.RptName = "COCertificateList";
      value.RptMenu = this.reportControl.value;
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == "List of Restricted Certificates") {
      this.filename = "RestrictedVersion3";
      value.RptName = "RestrictedVersion3";
      value.RptMenu = this.reportControl.value;
      value.RestrictCode = this.RestrictCode.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == "Transaction Journal") {
      this.filename = "TransactionJournal";
      value.RptName = "TransactionJournal";
      value.RptMenu = this.reportControl.value;
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      value.transactiontype = this.multiplecheckvalues;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;

    }
    else if (this.reportControl.value == "Transactions with Cost Basis Details") {
      this.filename = "TransfersWithCostBasis";
      value.RptName = "TransfersWithCostBasis";
      value.RptMenu = this.reportControl.value;
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == "Cash Disbursements (Cash Div, Cash in Lieu, Interest)") {
      this.filename = "CashDistributionReport";
      value.RptName = "CashDistributionReport";
      value.RptMenu = "Cash Disbursements";
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      this.holderid = this.holderID.value;
      console.log(this.Printoption.value)
      console.log(this.Exceloption.value)
    //   if (this.Printoption.value == null)
    //   value.PrintType = "PDF";
    // else
    //   value.PrintType = "Excel";
       value.PrintType=this.Printoption.value;
       if(this.Printoption.value=="Excel"){
           value.ExcelType=this.Exceloption.value
         }


    }

    else if (this.reportControl.value == 'Proxy Voting Summary') {
      this.filename = "ProxyReport_Summary";
      value.RptName = "ProxyReport_Summary";
      value.RptMenu = this.reportControl.value;
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == 'Proxy Voting Detail') {
      this.filename = "ProxyReport";
      value.RptName = "ProxyReport";
      value.RptMenu = this.reportControl.value;
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
       value.PrintType=this.Printoption.value;
    }

    else if (this.reportControl.value == 'Bond Transaction Journal') {
      this.filename = "BondsTransfersSimple";
      value.RptName = "BondsTransfersSimple";
      value.RptMenu = this.reportControl.value;
      value.begindate = this._datepipe.transform(this.begindate.value, "MM/dd/yyyy");
      value.enddate = this._datepipe.transform(this.enddate.value, "MM/dd/yyyy");
      value.holderID = this.holderID.value;
      value.SecurityID = this.securityID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
       value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == 'Bondholders Detail Holdings') {
      this.filename = "BondsHolderCertificateDetails";
      value.RptName = "BondsHolderCertificateDetails";
      value.RptMenu = this.reportControl.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == 'Bondholders Summary Holdings') {
      this.filename = "BondsShareholderWithSummaryDetail";
      value.RptName = "BondsShareholderWithSummaryDetail";
      value.RptMenu = this.reportControl.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == 'Bond Financial Advisor Report') {
      this.filename = "BondsFinancialAdvisorReport";
      value.RptName = "BondsFinancialAdvisorReport";
      value.RptMenu = this.reportControl.value;
      value.RptDate = this._datepipe.transform(this.date.value, "MM/dd/yyyy");
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      value.AdvisorID = this.AdvisorID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == 'Warrants Outstanding') {
      this.filename = "WarrantsAvailability";
      value.RptName = "WarrantsAvailability";
      value.RptMenu = this.reportControl.value;
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
      value.PrintType=this.Printoption.value;
    }
    else if (this.reportControl.value == 'Options Outstanding') {
      this.filename = "Options Availability";
      value.RptName = "Options Availability";
      value.RptMenu = this.reportControl.value;
      value.SecurityID = this.securityID.value;
      value.holderID = this.holderID.value;
      // if (this.Printoption.value == null)
      //   value.PrintType = "PDF";
      // else
      //   value.PrintType = "Excel";
        value.PrintType=this.Printoption.value;
     }

    //window.open("/dashboard/reportview");


    // if (this.reportControl.value == "Statements" && value.SecurityID == undefined) {
    //   this.loadingData = true;
    //   this.loadingData1 = false;
    //   this.notificationService.showNotification({ type: 'error', message: "Please select Security ID" });
    // }
    if (this.reportControl.value == "Statements" && value.holderID == undefined) {
      this.loadingData = true;
      this.loadingData1 = false;
      this.notificationService.showNotification({ type: 'error', message: "Please select Holder ID" });
    }
    else if (this.reportControl.value == "Statements" && value.holderID == "--- ALL ---") {
      this.loadingData = true;
      this.loadingData1 = false;
      this.notificationService.showNotification({ type: 'error', message: "Please select Holder ID" });
    }
    else if (this.reportControl.value == "Transaction Journal" && value.SecurityID == undefined) {
      this.loadingData = true;
      this.loadingData1 = false;
      this.notificationService.showNotification({ type: 'error', message: "Please select Security ID" });
    }

    else {
      if (value.holderID == "--- ALL ---")
        value.holderID = null;
      if (value.SecurityID == "--- ALL ---")
        value.SecurityID = null;
      if (value.AdvisorID == "--- ALL ---")
        value.AdvisorID = null;

      console.log(value.PendingCerts);
      console.log(value);
      console.log(value.begindate, value.enddate);
      //value = debounce(value);
      console.log(value, `${CONFIG.reportApiUrlBase}/${this.authService.CustomerID}`);
      this.backendService.postData(value, `${CONFIG.reportApiUrlBase}/${this.issuerid}/${this.authService.LoginType}`)
        //.pipe(timeout(5000000))
        .subscribe(response => {

          console.log(response);
          if (response['result']) {
            this.loadingData = true;
            this.loadingData1 = true;
            //this.setForm();
            this.reportGenerating = true;

            console.log(this.filename, this.holderid + "statrt");
            //this.currentDate = new Date();


            // if ((this.filename == "CashDistributionReport" && this.holderid == null) || (this.filename == "TAStatement" && this.holderid == null)) {
            //   console.log(this.filename, this.holderid + "statrt");
            //   this.currentDate = new Date();
            //   console.log("statrt1" + this.currentDate)
            //   if (this.filename == "CashDistributionReport") {
            //     this.delay(270000).then(any => {
            //       this.currentDate = new Date();
            //       console.log("statrt2" + this.currentDate)

            //       this.setForm();
            //       this.loadingData = true;
            //       this.Filenameuser = response["value"];
            //       sessionStorage.setItem('Filename', this.Filenameuser);
            //       this.authService.Filename = sessionStorage.getItem('Filename');
            //       sessionStorage.setItem('backendresponse', response['result']);
            //       this.authService.backendresponse = sessionStorage.getItem('backendresponse');
            //       this.openFile();
            //     });
            //   }
            //   else {
            //     this.delay(150000).then(any => {
            //       this.currentDate = new Date();
            //       console.log("statrt2" + this.currentDate)

            //       this.setForm();
            //       this.loadingData = true;
            //       this.Filenameuser = response["value"];
            //       sessionStorage.setItem('Filename', this.Filenameuser);
            //       this.authService.Filename = sessionStorage.getItem('Filename');
            //       sessionStorage.setItem('backendresponse', response['result']);
            //       this.authService.backendresponse = sessionStorage.getItem('backendresponse');
            //       this.openFile();
            //     });
            //   }

            //   //this.fileExists1("../assets/img/STC-Logo-201-200px.png")
            //   //  while (this.fileExists("../assets/img/STC-Logo-201-200px.png"))
            //   // {
            //   // timeout(60000)
            //   // }
            //   // console.log("statrt1")
            // } else {
            //   this.setForm();
            //   this.loadingData = true;
            //   this.Filenameuser = response["value"];
            //   sessionStorage.setItem('Filename', this.Filenameuser);
            //   this.authService.Filename = sessionStorage.getItem('Filename');
            //   sessionStorage.setItem('backendresponse', response['result']);
            //   this.authService.backendresponse = sessionStorage.getItem('backendresponse');
            //   this.openFile();
            // }
          }
          else {
            this.notificationService.showNotification({ type: 'error', message: response['msg'] });
          }
          console.log(response)
          //this.filename();        
        },
          (error) => {
            this.loadingData = true;
            this.loadingData1 = false;
            this.notificationService.showNotification({ type: 'error', message: 'Server Error - Please try again.' });
          });

    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }
  fileExists1(url: string): Observable<boolean> {
    console.log("hi" + url);
    return this._http.get(url)
      .pipe(
        map(response => {
          console.log(response)
          if (response === "success") {
            return true;
          }
          return false;
        }));
  }




  fileExists(url: string) {
    console.log("hi" + url);
    return this._http.get(url)
      .pipe(map(response => {
        console.log(response);
        if (response) {
          console.log(response);
          return response;
        } else {
          throw new Error('Error Getting Data');
        }
      })
      );
  }

  cancel() {
    this._router.navigate(['/dashboard/reports/print']);
    this.HolderShortNames = this.groupList;
    this.Printoption=new FormControl('PDF');
    // this.reportControl.setValue(''); *** taken care of by route navigation
    // this.setForm(); *** taken care of by route navigation
  }
  openFile() {
    console.log("test loaded");
    window.open("../assets/reports/" + this.Filenameuser);
    // window.open("F:/reports/anil.PDF");
  }

  // openFile() {

  //   window.open("/dashboard/reportview");
  // }
  filterAdvisorShortName(value) {

    console.log(value);
    if (value == "--- ALL ---") {
      this.AdvisorID = new FormControl("--- ALL ---");
    }
    else {
      this.filtercsutomerid = this.AdvisorIDSShortNames.filter(x => x.shortName == value);
      console.log(this.filtercsutomerid, this.filtercsutomerid[0].customerID)
      this.AdvisorID = new FormControl(this.filtercsutomerid[0].customerID);
    }
    this.backendService.getData(`${CONFIG.AdvisorIDSelectionchnage}/${this.currentSAccount}/${this.filtercsutomerid[0].customerID}`)
      .subscribe(response => {
        console.log(response);
        this.HolderIds = response["advlistchange"];
        this.HolderShortNames = response["advlistnamechange"];
      });
  }
  filterAdvisorid(value) {
    console.log(value);
    if (value == "--- ALL ---") {
      this.Advisorname = new FormControl("--- ALL ---");
    }
    else {
      this.filtershortname = this.AdvisorIDSShortNames.filter(x => x.customerID == value);
      //console.log(this.filtershortname, this.filtershortname[0].shortName);
      this.Advisorname = new FormControl(this.filtershortname[0].shortName);
    }

    if (this.currentSAccount == "") {
      this.currentSAccount = this.authService.singleIssuerID;
    }
    console.log(this.currentSAccount);
    this.backendService.getData(`${CONFIG.AdvisorIDSelectionchnage}/${this.currentSAccount}/${value}`)
      .subscribe(response => {
        console.log(response);
        this.HolderIds = response["advlistchange"];
        this.HolderShortNames = response["advlistnamechange"];
      });

  }

  filterShortName(value) {

    console.log(value);

    let stringToSplit = value;
    let x = stringToSplit.split(":");
    var selectedValue = x[0];
    // console.log(cselectedValueu);
    if (value == "--- ALL ---") {
      this.holderID = new FormControl("--- ALL ---");
    }
    else {
      console.log(this.HolderIdsHolderShortNames);
      this.filtercsutomerid = this.HolderIdsHolderShortNames.filter(x => x.customerID == selectedValue);

      console.log(this.filtercsutomerid, this.filtercsutomerid[0].customerID)
      this.holderID = new FormControl(this.filtercsutomerid[0].customerID);
     
    }

     // after search we bind selected dropdown and clear the search field for Holder dropdown
     this.HolderShortNames = this.groupList;
     this.TextSearch.controls['search'].setValue('')


  }
  filterHolderid(value) {
    console.log(value);
    if (value == "--- ALL ---") {
      this.shortname = new FormControl("--- ALL ---");
    }
    else {
      this.filtershortname = this.HolderIdsHolderShortNames.filter(x => x.customerID == value);
      console.log(this.filtershortname, this.filtershortname[0].shortName)
      this.shortname = new FormControl(this.filtershortname[0].shortName);
    }
  }


  // filterShortName(value)
  // {

  //   console.log(value);
  //   this.filtercsutomerid=this.HolderIds.filter(x=>x.shortName==value);
  //   console.log(this.filtercsutomerid,this.filtercsutomerid[0].customerID)
  //   this.holderID=new FormControl(this.filtercsutomerid[0].customerID);

  // }
  // filterHolderid(value)
  // {

  //   console.log(value);
  //   this.filtershortname=this.HolderIds.filter(x=>x.customerID==value);
  //   console.log(this.filtershortname,this.filtershortname[0].shortName)
  //   this.shortname=new FormControl(this.filtershortname[0].shortName);

  // }

  setForm() {

    this.date = new FormControl(new Date());
    this.begindate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate() - 30))));
    this.enddate = new FormControl(new Date(new Date(new Date().setDate(new Date().getDate()))));
    this.securityID = new FormControl();
    this.holderID = new FormControl();
    this.shortname = new FormControl();
    this.AdvisorID = new FormControl();
    this.RestrictCode = new FormControl();
    this.Advisorname = new FormControl();
    // this.optionalFilters = this._formBuilder.group({
    //     'date': ['', Validators.required],
    //     'HolderId': ['',],     
    //     'HolderShortName': [''],
    //     'RestrictCode': ['']         

    // });
  }
}
