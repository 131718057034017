import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ResetFormService } from './reset-form.service';
import { NotificationService } from '../notification/notification.service';
import CONFIG from 'config';
import { BackendService } from 'app/shared/backend.service';
import { AuthService } from 'app/shared/auth.service';

@Component({
    templateUrl: './tempcode.component.html',
    styles: [`
        .field-bottom-right {
            position: absolute;
            bottom: 12px;
            right: 0;
            z-index: 99;
        }
    `],
    //providers: [ResetFormService]
})

export class TempcodeComponent implements OnInit {
    tempForm: FormGroup;
    result: any;
    msg: any;
    forgotPassword: FormGroup;
    submitted1: boolean = false;
    submitted2: boolean = false;
    resetResponse: any;
    submitted: Boolean = false;
    constructor(
        public _router: Router,
        public _formBuilder: FormBuilder,
        public _resetFormService: ResetFormService,
        public _notificationService: NotificationService,
        public _backendService: BackendService,
        public _authService: AuthService,

    ) {
        this.setForm();
    }

    ngOnInit() {

    }
    Cancel() {
        console.log("testttt");
        //this.setForm();
        this.submitted = true;
        this._authService.loggedIn = false;
        this._router.navigate(['/']);
    }
    ValidateCode(user) {        
        console.log(this._authService.CustomerID);
        this._backendService.postData(user, `${CONFIG.tempcodeApiUrlBase}/${this._authService.CustomerID}/${this._authService.LoginType}`)
            .subscribe(response => {
                console.log(response);
                if (response['result']) {
                    this._notificationService.showNotification({ type: 'success', message: response['msg'] });
                    //this._authService.loggedIn = true;                   
                   // this._router.navigate(['/dashboard']);
                    console.log(response['requireChange']);
                    if (response['requireChange'] == "True") {
                        this._notificationService.showNotification({ type: 'warning', message: 'Please reset your password' });
                        this._router.navigate(['/set-security-questions']);
                    }
                    else {
                        //console.log("test else");
                        this._notificationService.showNotification({ type: 'success', message: response['msg'] });
                        this._router.navigate(['/dashboard']);
                    }
                }
                else {
                    this._notificationService.showNotification({ type: 'error', message: response['msg'] });

                }
            })
    }

    setForm() {
        this.tempForm = this._formBuilder.group({
            'TwoFactorCode': ['', Validators.required]
        });
    }
}
