import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class SearchService {
    constructor() {}

    findItems(item, group: Array<Object>, property: any): Promise<Array<Object>> {
        /*
        find item from larger group (within that group's property).  passed property can be a string (single field search) or array of strings (searching more than one field)
        uses data already provided on screen load (but we can add http module if we need a version that hits the backend.
        */
        return new Promise(
            function (resolve, reject) {
                let lowerItem = typeof item === 'string' ? item.toLowerCase() : item;
                let filtered: Array<Object> = [];

                filtered = group.filter(g => {
                    if (g[property]) {
                        let lowerProp = g[property].toLowerCase();

                        //search group (by property provided) for substring item
                        if (lowerProp.search(lowerItem) !== -1) return g[property];
                    }
                });

                resolve(filtered);
            }
        );
    }

    findItemsFromMultiple(item, group: Array<Object>, property: Array<string>): Promise<Array<Object>> {
        return new Promise(
            function (resolve, reject) {
                const itemToStr = item.toString();
                const lowerItem = itemToStr.toLowerCase();
                let filtered: Array<Object> = [];

                filtered = group.filter((g, index, arr) => {
                    for (let i = 0, l = property.length; i < l; i += 1) {
                        if(g[property[i]]) {
                            if(typeof g[property[i]] === 'string') {
                                const lowerProp = g[property[i]].toLowerCase();
                                if (lowerProp.includes(lowerItem)) {
                                    return g[property[i]];
                                }
                            } else {
                                if(g[property[i]].toString().includes(itemToStr)) {
                                    return g[property[i]];
                                }
                            }
                        }
                    }
                });

                resolve(filtered);
            }
        );
    }
    findItemsFromAccount(item, group: Array<Object>, property: Array<string>): Promise<Array<Object>> {
       
        return new Promise(
            
            function (resolve, reject) {
                     const itemToStr = item.toString();
                const lowerItem = itemToStr.toLowerCase();
                let filtered: Array<Object> = [];
                  filtered = group.filter((g, index, arr) => {

                    
                    for (var i = 0, l = property.length; i < l; i += 1) {


                        if (g[property[i]] && typeof g[property[i]] === 'string') {
                            let lowerProp = g[property[i]].toLowerCase();
                            if (lowerProp.includes(lowerItem)) {
                                return g[property[i]];
                            }
                        } else {
                            
                         if(g[property[i]].toString().includes(itemToStr))
                        {
                                return g[property[i]];
                            }
                        }
                    }
                });

                resolve(filtered);
            }
        );
    }

    findItemsInDateRange(start: Date, end: Date, group: Array<Object>, property: string): Promise<Array<Object>> {
        return new Promise(
            function (resolve, reject) {
                let filtered: Array<Object> = [];

                filtered = group.filter(g => {
                    let thisDateAsNumber = +new Date(g[property]);
                    if(thisDateAsNumber >= +start && thisDateAsNumber <= +end) {
                        return g[property];
                    }
                });

                resolve(filtered);
            }
        );
    }
}