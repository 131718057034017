import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NotificationService } from 'app/notification/notification.service';
import { AccountService } from 'app/shared/account.service';
import { AlertmessageServiceService } from 'app/shared/alertmessage-service.service';
import { AuthService } from 'app/shared/auth.service';
import { BackendService } from 'app/shared/backend.service';
import CONFIG from 'config';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-uploaddocuments',
  templateUrl: './uploaddocuments.component.html',
  styleUrls: ['./uploaddocuments.component.css']
})
export class UploaddocumentsComponent implements OnInit {


  uploadoptions:string[]=['All Holders','One Holder'];
  fileInputArray: { i: number, text: string }[] = [];
  formData = new FormData();
  uplaodto=new FormControl();
  holderID=new FormControl();
  documentpurpose=new FormControl();
  shortname=new FormControl();
  HolderIds: any;
  uploaddata:any;

  FileNamesArray:string[]=[];
  FileByteArray:string[]=[];
  FileCount:string[]=[];
  filestring:any;
  FileData:any={};
  HolderIdsHolderShortNames: any;
  HolderShortNames:any;
  filtershortname: any;
  filtercsutomerid:any;
  currentSAccount;
  currentSettlementAccountSubscription: Subscription;
  issuerid:any;

  constructor(public authService:AuthService,
    public backendService:BackendService,
    public notificationService:NotificationService,
    public accountService:AccountService) 
  { }

  ngOnInit(): void 
  {
    this.currentSettlementAccountSubscription = this.accountService.currentSettlementAccountChange$.subscribe(currentSettlementAccount => this.currentSettlementAccountChange(currentSettlementAccount));
       console.log(this.currentSettlementAccountSubscription);
       this.currentSAccount = this.accountService.currentSettlementAccount;
       console.log(this.currentSAccount);
       
       if(this.authService.IssuerCount=="1")
        this.issuerid=this.authService.singleIssuerID;
       else
        this.issuerid=this.currentSAccount;
    this.fileInputArray.push({ i: 0, text: "Select or Drag-and-Drop File" });
    this.Pageload();
  }
  currentSettlementAccountChange(value) {
    this.currentSAccount = value;
    
  }

Pageload()
{
  this.backendService.getData(`${CONFIG.uploadApiUrlBase}/${this.issuerid}`)
  .subscribe(response => {
    console.log(response);
    this.HolderIds = response["holderid"];
    this.HolderIdsHolderShortNames=response["holderidshortname"];
    this.HolderShortNames = response["shortname"];

  });

}
setForm()
{
  this.holderID=new FormControl();
  this.uplaodto=new FormControl();
  this.documentpurpose=new FormControl();
  this.FileNamesArray=[];
  this.FileByteArray=[];
  this.fileInputArray=[];
  this.fileInputArray.push({ i: 0, text: "Select or Drag-and-Drop File" });
}
cancel()
 {
  
  this.setForm();
}
filterShortName(value) 
{

  console.log(value);
 
  this.filtercsutomerid = this.HolderIdsHolderShortNames.filter(x => x.shortName == value);
  console.log(this.filtercsutomerid, this.filtercsutomerid[0].customerID)
  this.holderID = new FormControl(this.filtercsutomerid[0].customerID);
   

}
filterHolderid(value) 
{
  console.log(value);
  
    this.filtershortname = this.HolderIdsHolderShortNames.filter(x => x.customerID == value);
    console.log(this.filtershortname, this.filtershortname[0].shortName)
    this.shortname = new FormControl(this.filtershortname[0].shortName);
  
}

uploadData()
{
  this.uploaddata={};
  console.log(this.documentpurpose.value);
  if(this.documentpurpose.value==undefined || this.documentpurpose.value=='')
  {
    this.notificationService.showNotification({ type: 'error', message: "Please enter Document Purpose." });
  }
  else if(this.uplaodto.value=="One Holder" && this.holderID.value==undefined)
  {
    this.notificationService.showNotification({ type: 'error', message: "Please select holder id." });
  }
  else if(this.FileCount.length<1)
  {
    this.notificationService.showNotification({ type: 'error', message: "Please upload document." });
  }
  else
  {
    this.uploaddata.FileNamesArray=this.FileNamesArray;
    this.uploaddata.FileByteArray=this.FileByteArray;
    this.uploaddata.DocumentPurpose=this.documentpurpose.value;
    this.uploaddata.holderID=this.holderID.value;
    this.uploaddata.uplaodto=this.uplaodto.value;
    this.backendService.postData(this.uploaddata,`${CONFIG.uploadApiUrlBase}/${this.authService.UserID}/${this.issuerid}/${this.authService.LoginType}`)
        .subscribe(response => {
          console.log(response);
          if(response['result'])
          {
            this.notificationService.showNotification({ type: 'success', message: response['msg'] });
            this.setForm();
          }
          else
            this.notificationService.showNotification({ type: 'error', message: response['msg'] });
        });

  }

}

  upload(files, value) {
    console.log(value, files);
    console.log(files[0].name, files.length);
    if (files.length === 0)
        return;

    console.log(files[0].size);
    // if (files[0].size >= 50000000) {
    //     console.log("Maximum File Size Limit is 50MB.");
    //     window.alert("Maximum File Size Limit is 50MB.");
    // }
    // else {
        for (let file of files)
            this.formData.append(file.name, file);
          
           
            if(this.FileCount != null)
            {
              console.log("File count condition");
              for(let i=0;i<this.FileCount.length;i++)
              { 
                console.log(this.FileCount[i],value);
                if(this.FileCount[i]==value)
                {
                  this.FileNamesArray.splice(i,1);
                  this.FileByteArray.splice(i,1);
                  this.FileCount.splice(i,1);
                }
              }
            }

            var reader=new FileReader();
            reader.onload=this._handleReaderLoadded.bind(this);
            reader.readAsBinaryString(files[0]);
            this.FileNamesArray.push(files[0].name);
            this.FileCount.push(value);
            console.log(this.FileNamesArray,this.FileByteArray);


        var fileInputNo: number = value;
        console.log(fileInputNo);
        //console.log(this.fileInputArray, this.fileInputArray.length);

        if (fileInputNo === this.fileInputArray.length - 1) {
            var newI = this.fileInputArray.length;
            if (fileInputNo < 0) {
                this.fileInputArray.push({ i: newI, text: "Select or Drag-and-Drop File" });
            }
        }
        // console.log(this.fileInputArray[0].text, this.fileInputArray.length, files[0].name);
        if (files[0].name.length < 30) {
            var str = files[0].name;
            this.fileInputArray[fileInputNo].text = str.slice(0, 30);
        }
        else {
            var str = files[0].name;
            this.fileInputArray[fileInputNo].text = str.slice(0, 25) + '...' + str.slice(-4);
        }
    // }
}
_handleReaderLoadded(readerEvt)
  {
    var binaryString=readerEvt.target.result;
    this.filestring=btoa(binaryString); //Convaerting Binary String Data
    //console.log(this.filestring);
    this.FileData=this.filestring;
    this.FileByteArray.push(this.FileData);
    
  }

}
