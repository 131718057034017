import CONFIG from '../../config';

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PaginationService {
    private _entryRange = new BehaviorSubject<Array<number>>([0, 10]);
    entryRange = this._entryRange.asObservable();
    paginationArray: Array<number> = [1];
    paginationArrayDisplay: Array<number> = [];
    maxItemsOnPage: number = 10;
    totalPages: number = 0;
    totalEntries: number = 0;
    maxPagesInListing: number = 5;
    currentPage: number = 1;
    currentPageIndexTop: number = 1;

    constructor() {}

    setGrid(start, total?) {
      
         console.log(start, total);
        //passing a total entries variable is optional- needs to be done on first load and any filter by parent component
        if(total !== undefined) {
            this.totalEntries = total;
            console.log(this.totalEntries , total);
        }

        this.paginationArray = [1];

        let totalPages = Math.ceil(this.totalEntries / this.maxItemsOnPage);
        console.log(totalPages);

        //push each group of maxItemsOnPage into an array
        for(var i = 1; i < totalPages; i += 1) {
            this.paginationArray.push(i * this.maxItemsOnPage + 1);
            //console.log(i * this.maxItemsOnPage + 1);
        }

        //get the current page and check which should be displayed on screen
        let paginationArrayMid = this.paginationArray.indexOf(start);
        if(paginationArrayMid - 2 < 0) {
            paginationArrayMid = 2;
        } else if(paginationArrayMid + 3 > totalPages) {
            paginationArrayMid = totalPages - 3;
        }

        let paginationArrayMin = paginationArrayMid - 2;
        let paginationArrayMax = paginationArrayMid + 3;

        //limit # of page links in bottom section, show more as you scroll (but only if number of pages is greater than set max)
        if(totalPages > this.maxPagesInListing) {
            this.paginationArrayDisplay = this.paginationArray.slice(paginationArrayMin, paginationArrayMax);
        } else {
            this.paginationArrayDisplay = this.paginationArray;
        }

        this.currentPage = (start + (this.maxItemsOnPage - 1)) / this.maxItemsOnPage;

        //this is to pass the index of the first item on the page displaying back to the component using this service- allows for save/edit/delete on a page and stay on that page (instead of reverting to page 1).
        this.currentPageIndexTop = start;

        //fire observable update to send page range to pagination component and parent component
        this._entryRange.next([start - 1, start + (this.maxItemsOnPage - 1)]);
    }

    updateMaxItemsOnPage(value) {
        this.maxItemsOnPage = +value;
        this.setGrid(1);
    }
    updatePage(pageIndex, pageSize){
        this.maxItemsOnPage = +pageSize;
        // console.log(pageIndex*pageSize+1);
        
        this.setGrid(pageIndex*pageSize+1);
    }

}
